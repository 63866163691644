import { useQuery } from '@vue/apollo-composable'
import { OnboardingEventsInterface, OnboardingEventsResponseInterface, OnboardingEventsKeys } from '../interfaces/onboarding'
import { userClient, usersOfClient, roles, userClientQueryResult } from '../graphql/queries/userRolePermissions'
import { getPendingCheckoutsCount } from '../graphql/queries/pendingCheckouts'
import { useAuthStore } from './auth'
import { useUserStore } from './user'
import { useCountryStore } from './country'
import { locationInterface } from './../graphql/queries/locations'

export const useClientStore = defineStore('client-store', () => {
  const auth = useAuthStore()
  const user = useUserStore()
  const country = useCountryStore()
  const route = useRoute()
  const onboarding = ref<OnboardingEventsInterface>()
  const signTC = ref<boolean>(false)
  const companyTC = ref<string[]>([])
  const clientDetails = ref<boolean>(false)
  const clientJob = ref<boolean>(false)
  const clientFinanceUser = ref<any>(null)
  const onboardingCompleted = ref<boolean>(false)
  const openCheckouts = ref<number>()
  const clientUsers = ref<userClient>()
  const currentUserRoles = ref<roles>()
  const representativeRole = computed<boolean>(() => !!currentUserRoles.value?.is_official_representative)
  const plannerRole = ref<boolean>()
  const financeRole = ref<boolean>(false)
  const onboardingWizard = ref<boolean>()

  const locations = ref<Array<locationInterface>>()
  const firstLocationID = ref<string>('')
  const firstLocationShift = ref<string>('')
  const clientDetailsMissing = ref<Array<locationInterface>>()

  const fetchApi = inject('fetchApi') as Function

  function redirect() {
    if (!country.country) {
      return navigateTo('/nl-nl/signup')
    }
    if (!auth.isAuthenticated) {
      return navigateTo('/signup')
    }
    if (signTC.value) {
      return navigateTo('/terms-and-conditions')
    }
    if (!onboarding.value) { return }
    if (user.returnToUrl) {
      const to = user.returnToUrl
      user.returnToUrl = undefined
      return navigateTo(to)
    }
    if (route.path === '/') {
      if (onboardingCompleted.value) {
        return navigateTo('/dashboard')
      }
    }
    if (!onboardingCompleted.value) {
      if (onboardingWizard.value && representativeRole.value) {
        return navigateTo('/complete-your-account')
      }
      return navigateTo('/get-started')
    }
  }

  function getTermsStatus() {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated && country.backend) {
        const headers = {
          Authorization: auth.token
        }
        $fetch(country.backend + '/api/v3/clients/terms/needs-to-sign', {
          method: 'GET',
          headers
        })
          .then((response:any) => {
            signTC.value = response.data.needsToSign
            companyTC.value = response.data.companies
            resolve(response)
          })
          .catch((err: any) => reject(err))
      } else {
        resolve(false)
      }
    })
  }

  function getLocations() {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated) {
        fetchApi('/api/v4/projects', country.backend)
          .then((response: any) => response.json())
          .then(({ data }) => {
            locations.value = data.map(item => {
              return {
                id: item.id,
                name: item.client.name,
                locationId: item.client.key,
                projectId: item.key,
                clientId: item.client.id,
                extraValue: `${item.name} - ${item.client.establishment_address.line1}`,
              }
            })

            if (data.length) {
              firstLocationID.value = data[0].client.key
              getFirstLocationShift()
            }

            resolve(data)
          })
          .catch((err: any) => reject(err))
      } else {
        resolve(false)
      }
    })
  }
  function getOnboardingStatus() {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated && firstLocationID.value.length) {
        fetchApi(`/api/v4/client/${firstLocationID.value}/onboarding-events`, country.backend)
          .then((response : any) => response.json())
          .then((res: OnboardingEventsResponseInterface) => {
            onboarding.value = res.reduce<OnboardingEventsInterface>((acc, obj) => {
              const key = Object.keys(obj)[0] as keyof OnboardingEventsInterface
              acc[key] = !!obj[key]
              return acc
            }, {})
            onboardingCompleted.value = !!onboarding.value?.onboarding_fully_completed
            if (!(['name_added', 'description_added', 'image_added', 'iban_added', 'vat_added'] as OnboardingEventsKeys[]).every(k => onboarding.value?.[k])) {
              onboardingWizard.value = true
            }
            resolve(onboarding.value)
          })
          .catch((err: Error) => reject(err))
      } else {
        resolve(false)
      }
    })
  }

  function setWizard(status:boolean) {
    onboardingWizard.value = status
  }

  function getClientUsers() {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated && firstLocationID.value.length) {
        // eslint-disable-next-line max-len
        const { onResult, loading, onError } = useQuery<userClientQueryResult>(usersOfClient, { client_id: firstLocationID.value })

        onError(err => reject(err))

        onResult((result) => {
          if (loading.value) { return }
          clientUsers.value = result.data?.usersOfClient
          for (let u = 0; u < clientUsers.value!.length; u++) {
            if (user.user!.id === clientUsers.value[u].id) {
              currentUserRoles.value = clientUsers.value[u].roles
              representativeRole.value = clientUsers.value[u].roles.is_official_representative
              plannerRole.value = clientUsers.value[u].roles.is_planner
            }
          }
          resolve(result)
        })
      } else {
        resolve(false)
      }
    })
  }

  function getPendingCheckouts() {
    return new Promise((resolve, reject) => {
      if (auth.isAuthenticated) {
        const { onResult, loading, onError } = useQuery(getPendingCheckoutsCount)
        onError(err => reject(err))
        onResult((result) => {
          if (loading.value) { return }
          openCheckouts.value = result.data?.pendingCheckoutsCount
          resolve(result)
        })
      } else {
        resolve(false)
      }
    })
  }

  function getFirstLocationShift() {
    if (auth.isAuthenticated && locations.value?.length && user.shiftAddPermission.length) {
      const clientShifts = locations.value.filter(
        loc => user.shiftAddPermission.includes(loc.locationId)
      )

      if (clientShifts.length) {
        firstLocationShift.value = clientShifts[0]?.clientId
      }
    }
  }

  return {
    getTermsStatus,
    signTC,
    companyTC,
    locations,
    getLocations,
    onboarding,
    getOnboardingStatus,
    onboardingCompleted,
    clientDetails,
    clientJob,
    clientFinanceUser,
    getClientUsers,
    currentUserRoles,
    representativeRole,
    financeRole,
    plannerRole,
    getPendingCheckouts,
    openCheckouts,
    firstLocationID,
    setWizard,
    onboardingWizard,
    getFirstLocationShift,
    firstLocationShift,
    clientDetailsMissing,
    redirect,
  }
})
