export default () => {
  const config = useRuntimeConfig()
  const temperCountries = [
    {
      id: 1,
      country: 'nl',
      slug: 'nl-nl',
      locale: 'nl_NL',
      i18nLocale: 'nl_NL',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URL
    },
    {
      id: 2,
      country: 'nl',
      slug: 'en-nl',
      locale: 'en_EN',
      i18nLocale: 'en_NL',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URL
    },
    {
      id: 3,
      country: 'uk',
      slug: 'en-gb',
      locale: 'en_EN',
      i18nLocale: 'en_GB',
      currency: 'GBP',
      backend: config.public.TEMPER_API_URLuk
    },
    {
      id: 4,
      country: 'fr',
      slug: 'en-fr',
      locale: 'en_EN',
      i18nLocale: 'en_FR',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URLfr
    },
    {
      id: 5,
      country: 'fr',
      slug: 'fr-fr',
      locale: 'fr_FR',
      i18nLocale: 'fr_FR',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URLfr
    },
  ]

  return {
    temperCountries
  }
}
