/* eslint-disable */
import { useUserStore } from '../store/user'
import { useAuthStore } from '../store/auth'
import { IntercomPayload } from '../interfaces/intercom'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const APP_ID = config.public.INTERCOM_APP_ID
  const auth = useAuthStore()
  const userStore = useUserStore()
  let payload: IntercomPayload = {
    app_id: APP_ID,
  };

  (function() {
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function() {
        i.c(arguments)
      }
      i.q = []
      i.c = function(args) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function() {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + APP_ID
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()

  nuxtApp.hook('dataFetch:complete', async() => {
    if (auth.isAuthenticated) {
      const user = userStore.loggedInUser
      payload = {
        ...payload,
        email: user?.email,
        name: `${user?.first_name} ${user?.last_name}`,
        user_id: user?.id
      }
    }
    window.Intercom('boot', payload)
  })

  nuxtApp.provide('intercom', window.Intercom)
})
