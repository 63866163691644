import { useAuthStore } from '../store/auth'
import { useUserStore } from '../store/user'
import { useCountryStore } from '../store/country'
import { useClientStore } from '../store/client'
import { useFreeflexerProfileStore } from '../store/freeflexer/profile'
import { fetchDataClient, PostFetchHandlerClient } from '../composables/client/setUserData'
import { fetchDataFreeflexer, PostFetchHandlerFreeFlexer } from '../composables/freeflexer/setUserData'
import { AggregatedStores } from '../interfaces/stores'

const fetchData = (stores: AggregatedStores) => {
  stores.user.setLoading(true)
  return stores.user.getUser()
    .then(() => {
      if (stores.user.isClient) {
        return fetchDataClient(stores)
      } else {
        return fetchDataFreeflexer(stores)
      }
    })
}

const PostFetchHandler = (stores: AggregatedStores) => {
  if (stores.user.isClient) {
    PostFetchHandlerClient(stores)
  } else {
    PostFetchHandlerFreeFlexer(stores)
  }
  stores.user.setLoading(false)
}

export default defineNuxtRouteMiddleware((to) => {
  const { $router, callHook } = useNuxtApp()
  const stores: AggregatedStores = {
    auth: useAuthStore(),
    user: useUserStore(),
    client: useClientStore(),
    country: useCountryStore(),
    freeflexer: useFreeflexerProfileStore()
  }
  stores.auth.getToken()
  const missingUserData = ref<boolean>((
    stores.auth.isAuthenticated &&
    !stores.user.user &&
    !['login', 'logout', 'auth-callback', 'slug'].filter(r => to.name === r).length &&
    !stores.user.loading
  ) || stores.user.forceUserDataRefetch)
  const impersonation = document.cookie.match(new RegExp('(^| )' + 'im' + '=([^;]+)'))
  if (missingUserData.value) {
    if (
      $router.currentRoute.value.name !== 'index' &&
      to.name !== 'index' &&
      $router.currentRoute.value.path !== to.fullPath
    ) {
      stores.user.returnToUrl = to.fullPath
      return navigateTo('/')
    }
    stores.user.forceUserDataRefetch = false
    if (impersonation?.length) {
      stores.user.setImpersonation(true, impersonation[2])
    }

    fetchData(stores).then(() => {
      callHook('dataFetch:complete')
      PostFetchHandler(stores)
    })
  } else if (to.name === 'index') {
    PostFetchHandler(stores)
  }
  if (!stores.country.countryAlpha3) { stores.country.getCountrySettings() }
})
