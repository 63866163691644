import { AggregatedStores } from '../../interfaces/stores'

const fetchDataFreeflexer = async() => {
}

const PostFetchHandlerFreeFlexer = ({ freeflexer }:AggregatedStores) => {
  freeflexer.redirect()
}

export {
  fetchDataFreeflexer,
  PostFetchHandlerFreeFlexer
}
