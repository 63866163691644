const countrySpecificRoutesConfig : any = {
  uk: {
    contractor_jobs: 'find-work',
    work_at_client: 'work-at',
    contractors_match_presence: 'i-am-there',
    terms_and_conditions: 'terms-and-conditions',
    terms_and_conditions_clients: 'terms-and-conditions/clients',
    terms_and_conditions_freeflexers: 'terms-and-conditions/freeflexers',
    privacy_policy: 'https://go.temper.works/en-gb/privacypolicy',
    faq: 'https://help.temper.works/en'
  },
  nl: {
    contractor_jobs: 'werk-zoeken',
    work_at_client: 'werken-bij',
    contractors_match_presence: 'ik-ben-er',
    terms_and_conditions: 'algemene-voorwaarden',
    terms_and_conditions_clients: 'algemene-voorwaarden/opdrachtgevers',
    terms_and_conditions_freeflexers: 'algemene-voorwaarden/opdrachtnemers',
    privacy_policy: 'https://go.temper.works/privacybeleid',
    faq: 'https://help.temper.works/nl'
  },
  fr: {
    contractor_jobs: 'trouver-du-travail',
    work_at_client: 'travailler-a',
    contractors_match_presence: 'je-suis-la',
    terms_and_conditions: 'conditions-generales-d-utilisation',
    terms_and_conditions_clients: 'conditions-generales-d-utilisation/clients',
    terms_and_conditions_freeflexers: 'conditions-generales-d-utilisation/freeflexers',
    privacy_policy: 'https://go.temper.works/politique-de-confidentialite',
    faq: 'https://help.temper.works/fr'
  },
}

export default countrySpecificRoutesConfig
