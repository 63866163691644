declare global {
  interface Window {
    fbq: any;
    _fbq: any;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  function loadFacebookPixelScript() {
    return new Promise((resolve, reject) => {
      const fbq = window.fbq
      if (fbq) {
        return
      }

      window.fbq = (...args: any[]) => {
        if (window.fbq.callMethod) {
          window.fbq.callMethod(...args)
        } else {
          window.fbq.queue.push(args)
        }
      }

      if (!window._fbq) {
        window._fbq = fbq
      }

      window.fbq.push = window.fbq
      window.fbq.loaded = true
      window.fbq.version = '2.0'
      window.fbq.queue = []

      const scriptTag = document.createElement('script')
      scriptTag.async = true
      scriptTag.src = 'https://connect.facebook.net/en_US/fbevents.js'
      scriptTag.addEventListener('load', () => {
        resolve(true)
      })
      scriptTag.onerror = (err) => {
        reject(err)
      }
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode!.insertBefore(scriptTag, firstScriptTag)
    })
  }

  async function initPixel() {
    const config = useRuntimeConfig()
    await loadFacebookPixelScript()
    window.fbq('init', config.PIXEL_KEY)
    window.fbq('track', 'PageView')
  }

  nuxtApp.provide('initPixel', initPixel)
})
