import { useClientStore } from '../store/client'

export default defineNuxtRouteMiddleware((to) => {
  const clientStore = useClientStore()
  if (clientStore.signTC && to.name !== 'terms-and-conditions') {
    return navigateTo('/terms-and-conditions')
  }
  if (!clientStore.signTC && to.name === 'terms-and-conditions') {
    return clientStore.redirect()
  }
})
