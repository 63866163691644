import VueHotjar from 'vue-hotjar-next'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const APP_ID = config.public.HOTJAR_ID
  nuxtApp.vueApp.use(VueHotjar, {
    id: parseInt(APP_ID, 10),
    snippetVersion: 6,
  })
})
