export default defineNuxtRouteMiddleware(() => {
  const { $mixpanel } = useNuxtApp()

  try {
    $mixpanel.track_pageview()
  } catch {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Mixpanel not intialised, please provide the API key')
    }
  }
})
