import mixpanel from 'mixpanel-browser'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  mixpanel.init(config.public.MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV !== 'production',
    track_pageview: false,
    persistence: 'localStorage'
  })

  return {
    provide: {
      mixpanel: mixpanel
    }
  }
})
