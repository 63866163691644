/* eslint-disable import/no-extraneous-dependencies */
import gql from 'graphql-tag'

export const getClientUserPermissionsQuery = gql`
  query getClientUserPermissions {
    clientUserPermissions {
      client_id
      permissions
    }
  }
`

export interface ClientUserPermission {
  client_id: string;
  permissions: string[];
}

export interface ClientUserPermissionsQueryResult {
  clientUserPermissions: ClientUserPermission[];
}
