import { useClientStore } from '../store/client'

export default defineNuxtPlugin((nuxtApp) => {
  const clientStore = useClientStore()
  const config = useRuntimeConfig()

  window.onUsersnapCXLoad = function(api: any) {
    window.Usersnap = api
    api.init()

    api.on('open', function(event) {
      event.api.setValue('custom', {
        client_id: clientStore?.user.uuid
      })
    })
  }

  const script = document.createElement('script')
  script.defer = true
  script.src = `https://widget.usersnap.com/global/load/${config.public.USERSNAP_API_KEY}?onload=onUsersnapCXLoad`
  document.head.appendChild(script)
})
