import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45set_45user_45global from "/opt/build/repo/middleware/1.setUser.global.ts";
import _2_45toc_45global from "/opt/build/repo/middleware/2.TOC.global.ts";
import mixpanel_45global from "/opt/build/repo/middleware/mixpanel.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45set_45user_45global,
  _2_45toc_45global,
  mixpanel_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs"),
  "is-client": () => import("/opt/build/repo/middleware/isClient.ts"),
  "is-freeflexer": () => import("/opt/build/repo/middleware/isFreeflexer.ts"),
  "official-rep": () => import("/opt/build/repo/middleware/officialRep.ts"),
  onboarding: () => import("/opt/build/repo/middleware/onboarding.ts"),
  unauth: () => import("/opt/build/repo/middleware/unauth.ts")
}