import { useUserStore } from '../store/user'
import { useAuthStore } from '../store/auth'
import { useClientStore } from '../store/client'

const impersonation = document.cookie.match(new RegExp('(^| )' + 'im' + '=([^;]+)'))
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('setUser', () => {
    const userStore = useUserStore()
    const authStore = useAuthStore()
    const clientStore = useClientStore()
    if (impersonation?.length) {
      userStore.setImpersonation(true, impersonation[2])
    }
    authStore.getToken()
    userStore.getUser()
    clientStore.getLocations()
    clientStore.getOnboardingStatus()
    clientStore.getTermsStatus()
    clientStore.getPendingCheckouts()
    clientStore.getClientUsers()
  })
})
