import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexbi3PWwfsxhMeta } from "/opt/build/repo/pages/404/index.vue?macro=true";
import { default as aanleveren_45belastingdienstwpq4joQozkMeta } from "/opt/build/repo/pages/aanleveren-belastingdienst.vue?macro=true";
import { default as callbackFp0IkDkN7aMeta } from "/opt/build/repo/pages/auth/callback.vue?macro=true";
import { default as complete_45your_45accountbv2KcfYX4iMeta } from "/opt/build/repo/pages/complete-your-account.vue?macro=true";
import { default as indexrq2V8c2NuNMeta } from "/opt/build/repo/pages/dashboard/client/invoices/index.vue?macro=true";
import { default as index4B0JUJTgVIMeta } from "/opt/build/repo/pages/dashboard/client/jobs/[...slug]/index.vue?macro=true";
import { default as create3FdNSzqHQQMeta } from "/opt/build/repo/pages/dashboard/client/jobs/[...slug]/shifts/create.vue?macro=true";
import { default as new_45job_45template4tdj9cBvVUMeta } from "/opt/build/repo/pages/dashboard/client/jobs/new-job-template.vue?macro=true";
import { default as notification_45settingshwDtOUm7KSMeta } from "/opt/build/repo/pages/dashboard/client/notification-settings.vue?macro=true";
import { default as statisticsPazh2LErInMeta } from "/opt/build/repo/pages/dashboard/client/statistics.vue?macro=true";
import { default as indexx76vbhAHkwMeta } from "/opt/build/repo/pages/dashboard/contractor/invoices/index.vue?macro=true";
import { default as indexViWjQzLqkmMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as indexaGFUkKyZt9Meta } from "/opt/build/repo/pages/dashboard/planning/index.vue?macro=true";
import { default as complete_45profiletlrlkQRWq9Meta } from "/opt/build/repo/pages/freeflexer/complete-profile.vue?macro=true";
import { default as insurancesW4xlHlofiIMeta } from "/opt/build/repo/pages/freeflexer/insurances.vue?macro=true";
import { default as company_45identification_45numberQzjGMockFKMeta } from "/opt/build/repo/pages/freeflexer/onboarding/company-identification-number.vue?macro=true";
import { default as profileDvV9YNGE1wMeta } from "/opt/build/repo/pages/freeflexer/profile.vue?macro=true";
import { default as indexL1rSuOa2ZbMeta } from "/opt/build/repo/pages/get-started/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as index9WruYoq02MMeta } from "/opt/build/repo/pages/login/index.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as platform_45guideQ5i0rzTcNTMeta } from "/opt/build/repo/pages/platform-guide.vue?macro=true";
import { default as account_45activatedFTRRwGNFs4Meta } from "/opt/build/repo/pages/signup/account-activated.vue?macro=true";
import { default as activate_45accountb4fv2RNAOSMeta } from "/opt/build/repo/pages/signup/activate-account.vue?macro=true";
import { default as already_45activatedz2oTCuHt0sMeta } from "/opt/build/repo/pages/signup/already-activated.vue?macro=true";
import { default as index49X2UrbFTSMeta } from "/opt/build/repo/pages/signup/index.vue?macro=true";
import { default as terms_45and_45conditionsutYdRHTG1cMeta } from "/opt/build/repo/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexbi3PWwfsxhMeta?.name ?? "404",
    path: indexbi3PWwfsxhMeta?.path ?? "/404",
    meta: indexbi3PWwfsxhMeta || {},
    alias: indexbi3PWwfsxhMeta?.alias || [],
    redirect: indexbi3PWwfsxhMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/404/index.vue").then(m => m.default || m)
  },
  {
    name: aanleveren_45belastingdienstwpq4joQozkMeta?.name ?? "aanleveren-belastingdienst",
    path: aanleveren_45belastingdienstwpq4joQozkMeta?.path ?? "/aanleveren-belastingdienst",
    meta: aanleveren_45belastingdienstwpq4joQozkMeta || {},
    alias: aanleveren_45belastingdienstwpq4joQozkMeta?.alias || [],
    redirect: aanleveren_45belastingdienstwpq4joQozkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/aanleveren-belastingdienst.vue").then(m => m.default || m)
  },
  {
    name: callbackFp0IkDkN7aMeta?.name ?? "auth-callback",
    path: callbackFp0IkDkN7aMeta?.path ?? "/auth/callback",
    meta: callbackFp0IkDkN7aMeta || {},
    alias: callbackFp0IkDkN7aMeta?.alias || [],
    redirect: callbackFp0IkDkN7aMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: complete_45your_45accountbv2KcfYX4iMeta?.name ?? "complete-your-account",
    path: complete_45your_45accountbv2KcfYX4iMeta?.path ?? "/complete-your-account",
    meta: complete_45your_45accountbv2KcfYX4iMeta || {},
    alias: complete_45your_45accountbv2KcfYX4iMeta?.alias || [],
    redirect: complete_45your_45accountbv2KcfYX4iMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/complete-your-account.vue").then(m => m.default || m)
  },
  {
    name: indexrq2V8c2NuNMeta?.name ?? "dashboard-client-invoices",
    path: indexrq2V8c2NuNMeta?.path ?? "/dashboard/client/invoices",
    meta: indexrq2V8c2NuNMeta || {},
    alias: indexrq2V8c2NuNMeta?.alias || [],
    redirect: indexrq2V8c2NuNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/client/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: index4B0JUJTgVIMeta?.name ?? "dashboard-client-jobs-slug",
    path: index4B0JUJTgVIMeta?.path ?? "/dashboard/client/jobs/:slug(.*)*",
    meta: index4B0JUJTgVIMeta || {},
    alias: index4B0JUJTgVIMeta?.alias || [],
    redirect: index4B0JUJTgVIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/client/jobs/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: create3FdNSzqHQQMeta?.name ?? "dashboard-client-jobs-slug-shifts-create",
    path: create3FdNSzqHQQMeta?.path ?? "/dashboard/client/jobs/:slug(.*)*/shifts/create",
    meta: create3FdNSzqHQQMeta || {},
    alias: create3FdNSzqHQQMeta?.alias || [],
    redirect: create3FdNSzqHQQMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/client/jobs/[...slug]/shifts/create.vue").then(m => m.default || m)
  },
  {
    name: new_45job_45template4tdj9cBvVUMeta?.name ?? "dashboard-client-jobs-new-job-template",
    path: new_45job_45template4tdj9cBvVUMeta?.path ?? "/dashboard/client/jobs/new-job-template",
    meta: new_45job_45template4tdj9cBvVUMeta || {},
    alias: new_45job_45template4tdj9cBvVUMeta?.alias || [],
    redirect: new_45job_45template4tdj9cBvVUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/client/jobs/new-job-template.vue").then(m => m.default || m)
  },
  {
    name: notification_45settingshwDtOUm7KSMeta?.name ?? "dashboard-client-notification-settings",
    path: notification_45settingshwDtOUm7KSMeta?.path ?? "/dashboard/client/notification-settings",
    meta: notification_45settingshwDtOUm7KSMeta || {},
    alias: notification_45settingshwDtOUm7KSMeta?.alias || [],
    redirect: notification_45settingshwDtOUm7KSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/client/notification-settings.vue").then(m => m.default || m)
  },
  {
    name: statisticsPazh2LErInMeta?.name ?? "dashboard-client-statistics",
    path: statisticsPazh2LErInMeta?.path ?? "/dashboard/client/statistics",
    meta: statisticsPazh2LErInMeta || {},
    alias: statisticsPazh2LErInMeta?.alias || [],
    redirect: statisticsPazh2LErInMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/client/statistics.vue").then(m => m.default || m)
  },
  {
    name: indexx76vbhAHkwMeta?.name ?? "dashboard-contractor-invoices",
    path: indexx76vbhAHkwMeta?.path ?? "/dashboard/contractor/invoices",
    meta: indexx76vbhAHkwMeta || {},
    alias: indexx76vbhAHkwMeta?.alias || [],
    redirect: indexx76vbhAHkwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/contractor/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard",
    path: indexViWjQzLqkmMeta?.path ?? "/dashboard",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexaGFUkKyZt9Meta?.name ?? "dashboard-planning",
    path: indexaGFUkKyZt9Meta?.path ?? "/dashboard/planning",
    meta: indexaGFUkKyZt9Meta || {},
    alias: indexaGFUkKyZt9Meta?.alias || [],
    redirect: indexaGFUkKyZt9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/planning/index.vue").then(m => m.default || m)
  },
  {
    name: complete_45profiletlrlkQRWq9Meta?.name ?? "freeflexer-complete-profile",
    path: complete_45profiletlrlkQRWq9Meta?.path ?? "/freeflexer/complete-profile",
    meta: complete_45profiletlrlkQRWq9Meta || {},
    alias: complete_45profiletlrlkQRWq9Meta?.alias || [],
    redirect: complete_45profiletlrlkQRWq9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/freeflexer/complete-profile.vue").then(m => m.default || m)
  },
  {
    name: insurancesW4xlHlofiIMeta?.name ?? "freeflexer-insurances",
    path: insurancesW4xlHlofiIMeta?.path ?? "/freeflexer/insurances",
    meta: insurancesW4xlHlofiIMeta || {},
    alias: insurancesW4xlHlofiIMeta?.alias || [],
    redirect: insurancesW4xlHlofiIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/freeflexer/insurances.vue").then(m => m.default || m)
  },
  {
    name: company_45identification_45numberQzjGMockFKMeta?.name ?? "freeflexer-onboarding-company-identification-number",
    path: company_45identification_45numberQzjGMockFKMeta?.path ?? "/freeflexer/onboarding/company-identification-number",
    meta: company_45identification_45numberQzjGMockFKMeta || {},
    alias: company_45identification_45numberQzjGMockFKMeta?.alias || [],
    redirect: company_45identification_45numberQzjGMockFKMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/freeflexer/onboarding/company-identification-number.vue").then(m => m.default || m)
  },
  {
    name: profileDvV9YNGE1wMeta?.name ?? "freeflexer-profile",
    path: profileDvV9YNGE1wMeta?.path ?? "/freeflexer/profile",
    meta: profileDvV9YNGE1wMeta || {},
    alias: profileDvV9YNGE1wMeta?.alias || [],
    redirect: profileDvV9YNGE1wMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/freeflexer/profile.vue").then(m => m.default || m)
  },
  {
    name: indexL1rSuOa2ZbMeta?.name ?? "get-started",
    path: indexL1rSuOa2ZbMeta?.path ?? "/get-started",
    meta: indexL1rSuOa2ZbMeta || {},
    alias: indexL1rSuOa2ZbMeta?.alias || [],
    redirect: indexL1rSuOa2ZbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/get-started/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index9WruYoq02MMeta?.name ?? "login",
    path: index9WruYoq02MMeta?.path ?? "/login",
    meta: index9WruYoq02MMeta || {},
    alias: index9WruYoq02MMeta?.alias || [],
    redirect: index9WruYoq02MMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: logoutlLSqGnk5PzMeta?.name ?? "logout",
    path: logoutlLSqGnk5PzMeta?.path ?? "/logout",
    meta: logoutlLSqGnk5PzMeta || {},
    alias: logoutlLSqGnk5PzMeta?.alias || [],
    redirect: logoutlLSqGnk5PzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: platform_45guideQ5i0rzTcNTMeta?.name ?? "platform-guide",
    path: platform_45guideQ5i0rzTcNTMeta?.path ?? "/platform-guide",
    meta: platform_45guideQ5i0rzTcNTMeta || {},
    alias: platform_45guideQ5i0rzTcNTMeta?.alias || [],
    redirect: platform_45guideQ5i0rzTcNTMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/platform-guide.vue").then(m => m.default || m)
  },
  {
    name: account_45activatedFTRRwGNFs4Meta?.name ?? "signup-account-activated",
    path: account_45activatedFTRRwGNFs4Meta?.path ?? "/signup/account-activated",
    meta: account_45activatedFTRRwGNFs4Meta || {},
    alias: account_45activatedFTRRwGNFs4Meta?.alias || [],
    redirect: account_45activatedFTRRwGNFs4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup/account-activated.vue").then(m => m.default || m)
  },
  {
    name: activate_45accountb4fv2RNAOSMeta?.name ?? "signup-activate-account",
    path: activate_45accountb4fv2RNAOSMeta?.path ?? "/signup/activate-account",
    meta: activate_45accountb4fv2RNAOSMeta || {},
    alias: activate_45accountb4fv2RNAOSMeta?.alias || [],
    redirect: activate_45accountb4fv2RNAOSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup/activate-account.vue").then(m => m.default || m)
  },
  {
    name: already_45activatedz2oTCuHt0sMeta?.name ?? "signup-already-activated",
    path: already_45activatedz2oTCuHt0sMeta?.path ?? "/signup/already-activated",
    meta: already_45activatedz2oTCuHt0sMeta || {},
    alias: already_45activatedz2oTCuHt0sMeta?.alias || [],
    redirect: already_45activatedz2oTCuHt0sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup/already-activated.vue").then(m => m.default || m)
  },
  {
    name: index49X2UrbFTSMeta?.name ?? "signup",
    path: index49X2UrbFTSMeta?.path ?? "/signup",
    meta: index49X2UrbFTSMeta || {},
    alias: index49X2UrbFTSMeta?.alias || [],
    redirect: index49X2UrbFTSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsutYdRHTG1cMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsutYdRHTG1cMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsutYdRHTG1cMeta || {},
    alias: terms_45and_45conditionsutYdRHTG1cMeta?.alias || [],
    redirect: terms_45and_45conditionsutYdRHTG1cMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]