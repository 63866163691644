import { verification } from '../interfaces/verifications'
import { errorItem } from '../interfaces/errors'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.provide('validator', (items:Array<verification>, error:Array<errorItem>, text:any) => {
    for (let x = 0; x < items.length; x++) {
      if (!items[x].name) {
        const i = error.findIndex(i => i.type === items[x].type)
        if (i !== -1) {
          error.splice(i, 1)
        }
        error.push({
          type: items[x].type,
          message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsRequired')
        })
      } else if (items[x].type === 'date') {
        if (items[x].name.length === 0) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsRequired')
          })
        }
      } else if (items[x].type === 'photos') {
        if (items[x].name.length === 0) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsRequired')
          })
        }
      } else if (items[x].type === 'description') {
        if (items[x].name.length > 0 && items[x].name.length < 20) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: text.t('forms.textareaInputTooShort')
          })
        }
        if (items[x].name.length > 400) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: text.t('forms.textareaInputTooLong')
          })
        }
      } else if (items[x].name.length === 1 && !Array.isArray(items[x].name)) {
        const i = error.findIndex(i => i.type === items[x].type)
        if (i !== -1) {
          error.splice(i, 1)
        }
        error.push({
          type: items[x].type,
          message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsInputTooShort')
        })
      } else if (items[x].name.length <= 2 && items[x].name.match(/ /)) {
        const i = error.findIndex(i => i.type === items[x].type)
        if (i !== -1) {
          error.splice(i, 1)
        }
        error.push({
          type: items[x].type,
          message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsRequired')
        })
      } else if (items[x].name.length >= 255) {
        const i = error.findIndex(i => i.type === items[x].type)
        if (i !== -1) {
          error.splice(i, 1)
        }
        error.push({
          type: items[x].type,
          message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsInputTooLong')
        })
      } else if (items[x].name.match(/^.(?=.*(["(),:;<>[\]-^~])){1}.*$/) && items[x].type !== 'password' && items[x].type !== 'companyName') {
        const i = error.findIndex(i => i.type === items[x].type)
        if (i !== -1) {
          error.splice(i, 1)
        }
        error.push({ type: items[x].type, message: text.t('forms.fieldsInputCharacters') })
      } else if (items[x].type === 'email') {
        if (items[x].name.length <= 7) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
        } else if (!items[x].name.toLowerCase().match(/@/)) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
        } else if (!items[x].name.toLowerCase().match(/./)) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
        } else if (items[x].name.length >= 255) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
        }
      } else if (items[x].name.length >= 2 && items[x].name.length < 255 && error.length > 0) {
        const i = error.findIndex(i => i.type === items[x].type)
        if (i !== -1) {
          error.splice(i, 1)
        }
      }
    }
  })
})
