import {
  CompleteProfileSteps,
  CompleteProfileSections
} from 'temper-types-library'

import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import {
  WorkerProfile,
  WorkExperience,
  JobRatingAndSkills,
  InvoiceDetails,
  WorkerProfileStore,
  AuthStore
} from 'temper-types-library/src/types/interfaces/Common/stores'
import {
  ApiWorkerOnboarding
} from '../../interfaces/api/worker'
import { useUserStore } from '../user'
import { useCountryStore } from '../country'
import { useAuthStore } from '../auth'

export const useFreeflexerProfileStore: () => WorkerProfileStore = defineStore('worker-profile-store', () => {
  const user = useUserStore()
  const country = useCountryStore()
  const auth: AuthStore = useAuthStore()
  const navigate = inject('navigate') as Function
  const fetchApi = inject('fetchApi') as Function

  const onboardingProgress = ref<ApiWorkerOnboarding | null>({ sections: undefined })

  function setOnboardingProgress(onboardingData: ApiWorkerOnboarding) {
    if (!onboardingData || !onboardingData.sections) return

    return onboardingData.sections
      .filter(section =>
        Object.values(CompleteProfileSections).includes(section.key)
      )
      .map(section => {
        const steps = section.steps.filter(step =>
          Object.values(CompleteProfileSteps).includes(step.key)
        )

        return {
          ...section,
          steps
        }
      })
  }
  const profile = ref<WorkerProfile>()
  const workExperience = ref<WorkExperience>()
  const invoiceDetails = ref<InvoiceDetails>()
  const jobRatingsAndSkills = ref<JobRatingAndSkills[]>([])

  function getOnboardingProgress() {
    if (auth.token) {
      const headers = {
        Authorization: auth.token
      }
      $fetch(`${country.backend}/api/v4/worker/onboarding`, {
        method: 'GET',
        headers
      })
        .then((response:any) => {
    onboardingProgress.value.sections = setOnboardingProgress(response)
        })
    }
  }

  const completeProfilePercentage = computed(() => {
    if (!onboardingProgress?.value?.sections || onboardingProgress.value.sections.length === 0) return 0

    let completedSteps = 0
    let allSteps = 0

    onboardingProgress.value.sections.forEach(section => {
      allSteps = allSteps + section.steps.length
      completedSteps = completedSteps + section.steps.filter(step => step.show_in_screen ? step.completed : true).length
    })

    return 100 * (completedSteps / allSteps)
  })

  function getProfile() {
    return fetchApi('/api/v4/worker/profile', country.backend)
      .then(res => res.json())
      .then((response:any) => {
        // This renames the zipcode to zip_code, for it to be picked up correctly by the address component.
        const tmp = response
        if (tmp.home_address) {
          delete Object?.assign(tmp.home_address, { zip_code: response.home_address.zipcode })?.zipcode
        }
        profile.value = tmp
      })
  }

  function getWorkExperience() {
    return fetchApi('/api/v4/worker/profile/work-experiences', country.backend)
      .then(res => res.json())
      .then((response:any) => {
        workExperience.value = response
      })
  }

  function getInvoiceDetails() {
    return fetchApi('/api/v4/worker/profile/invoice-details', country.backend)
      .then(res => res.json())
      .then((response:any) => {
        const tmp = response
        if (tmp.address) {
          delete Object?.assign(tmp.address, { zip_code: response.address.zipcode })?.zipcode
        }
        invoiceDetails.value = tmp
      })
  }

  function getJobRatingsAndSkills() {
    return fetchApi('/api/v4/worker/profile/rating-and-skills-by-job-category', country.backend)
      .then(res => res.json())
      .then((response:any) => {
        jobRatingsAndSkills.value = response
      })
  }

  function redirect() {
    const direction = document.cookie.match(new RegExp('(^| )' + 'redirectUrl' + '=([^;]+)'))
    if (direction) {
      clearCookie()
    }
    if (direction && direction[2].includes('company-identification-number') && direction[2].includes('freeflexer')) {
      return navigate('/freeflexer/onboarding/company-identification-number')
    } else if (direction && direction[2].includes('complete-profile') && direction[2].includes('freeflexer')) {
      return navigate('/freeflexer/complete-profile')
    } else if (user.returnToUrl) {
      const to = user.returnToUrl
      user.returnToUrl = undefined
      return navigateTo(to)
    }
  }

  function clearCookie() {
    document.cookie = ['redirectUrl=', `expires=${new Date(0).toString()}`, 'path=/'].join('; ')
  }

  return {
    onboardingProgress,
    completeProfilePercentage,
    profile,
    workExperience,
    invoiceDetails,
    jobRatingsAndSkills,
    getOnboardingProgress,
    redirect,
    clearCookie,
    getProfile,
    getWorkExperience,
    getInvoiceDetails,
    getJobRatingsAndSkills
  }
})
