import { AggregatedStores } from '../../interfaces/stores'

const fetchDataClient = async({ client, user }: AggregatedStores) => {
  const BREAK = 'break'
  const tasks = [
    client.getLocations,
    async() => {
      await client.getTermsStatus()
      if (client.signTC) {
        return BREAK
      }
    },
    () => Promise.all([
      user.getPermissions(),
      // user.getTemperRulesStatus()
    ]),
    client.getClientUsers,
    () => {
      client.getPendingCheckouts()
      return client.getOnboardingStatus()
    }
  ]

  for (const task of tasks) {
    const res = await task()
      .catch(err => console.warn(err))
    if (res === BREAK) { break }
  }
}

const PostFetchHandlerClient = ({ client }: AggregatedStores) => {
  client.redirect()
}

export {
  fetchDataClient,
  PostFetchHandlerClient
}
