// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag'

export const usersOfClient = gql`
  query usersOfClient($client_id: String!) {
    usersOfClient(client_id: $client_id) {
      ... on User {
        id
        roles {
          ... on UserRolePivot {
          is_official_representative
          is_user_admin
          is_financial_department
          is_planner
          }
        }
      }
    }
  }
`

export interface roles {
  is_official_representative: boolean
  is_financial_department: boolean
  is_user_admin: boolean
  is_planner: boolean
}

export interface userClient {
  id: string,
  roles: roles
}

export type userClientQueryResult = { usersOfClient: userClient[] }
