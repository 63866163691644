declare global {
  interface Window {
    dataLayer: any
  }
  let gtag: Function
}

export default defineNuxtPlugin((nuxtApp) => {
  window.dataLayer = window.dataLayer || []

  function gtag() {
    dataLayer.push(arguments)
  }

  gtag('consent', 'default', {
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 2000
  })

  gtag('set', 'ads_data_redaction', true)

  nuxtApp.provide('gtag', gtag)
})
