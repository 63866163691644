/* eslint-disable camelcase */
import { Permission } from './enums/Permission'

export interface IndexedPermissions {
  [permission: string]: { [clientId: string]: true };
}

export type EntityWithPermissions = { [key: string]: any } & {
  permissions: Permission[] | null;
};

export function indexPermissions(
  rawPermissions: {
    client_id: string
    permissions: string[]
  }[],
): IndexedPermissions {
  if (!rawPermissions) { return {} }
  return rawPermissions.reduce((acc, { client_id, permissions }) => {
    permissions.forEach((permission) => {
      if (!acc[permission]) { acc[permission] = {} }

      acc[permission][client_id] = true
    })
    return acc
  }, {} as IndexedPermissions)
}

export function filterForPermissions(
  permissions: IndexedPermissions,
  links: EntityWithPermissions[],
) {
  return links.filter((link: any) => {
    if (!link.permissions) { return true }
    if (!permissions) { return false }

    return link.permissions.find(
      (permission: string) => !permissions[permission],
    )
  })
}
