import { fetchApi, readableCurrency, readableDate, readableTime, validator, InputValidationPlugin, sentryPlugin, mixpanel } from 'temper-component-library'
import { useCountryStore } from '../store/country'

export default defineNuxtPlugin({
  async setup(nuxtApp) {
    const countryStore = useCountryStore()
    nuxtApp.vueApp.use(fetchApi)
    nuxtApp.vueApp.use(readableCurrency)
    nuxtApp.vueApp.use(readableDate)
    nuxtApp.vueApp.use(readableTime)
    nuxtApp.vueApp.use(validator)
    nuxtApp.vueApp.use(mixpanel, { country: countryStore.country.toUpperCase() })
    nuxtApp.vueApp.use(InputValidationPlugin, { t: nuxtApp.$i18n.t })
  },
  hooks: {
    'app:created'() {
      const config = useRuntimeConfig()
      const nuxtApp = useNuxtApp()
      nuxtApp.vueApp.use(sentryPlugin, {
        router: useRouter(),
        sentryDsn: config.public.SENTRY_DSN,
        enableSentry: process.env.NODE_ENV === 'production'
      })
    }
  }
})
