// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag'

export const meQuery = gql`
  query FindMe {
    me {
      ... on User {
        id
        first_name
        last_name
        email
        mobile
      }
    }
  }
`

export interface User {
  id: string
  first_name: string
  last_name: string
  email: string
  mobile: string
}

export type MeQueryResult = { me: User };
