import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import http_plugin_nuxt_BmzkAX86rT from "/opt/build/repo/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/opt/build/repo/.nuxt/auth.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import FBPixel_pfxXiHSWff from "/opt/build/repo/plugins/FBPixel.ts";
import apollo_client_VTaW5dp4nd from "/opt/build/repo/plugins/apollo-client.ts";
import changeCountry_q2Al21Mu2F from "/opt/build/repo/plugins/changeCountry.ts";
import googleTagManager_QmmW6lPxbX from "/opt/build/repo/plugins/googleTagManager.ts";
import gtag_RbOvmu12fv from "/opt/build/repo/plugins/gtag.ts";
import hotjar_pBWYs5JQbk from "/opt/build/repo/plugins/hotjar.ts";
import importPlugins_dSrmlJHrsT from "/opt/build/repo/plugins/importPlugins.ts";
import intercom_tYaoKVt49Q from "/opt/build/repo/plugins/intercom.ts";
import mixpanel_vYbGOFriae from "/opt/build/repo/plugins/mixpanel.ts";
import navigation_QptjALbk5P from "/opt/build/repo/plugins/navigation.ts";
import setCountry_56dWCNFicO from "/opt/build/repo/plugins/setCountry.ts";
import setUser_7ED3DT1SjL from "/opt/build/repo/plugins/setUser.ts";
import userSnap_JoYSL8axbe from "/opt/build/repo/plugins/userSnap.ts";
import validationFields_ioW4FysPzn from "/opt/build/repo/plugins/validationFields.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  http_plugin_nuxt_BmzkAX86rT,
  auth_plugin_6DDHMgc34l,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  FBPixel_pfxXiHSWff,
  apollo_client_VTaW5dp4nd,
  changeCountry_q2Al21Mu2F,
  googleTagManager_QmmW6lPxbX,
  gtag_RbOvmu12fv,
  hotjar_pBWYs5JQbk,
  importPlugins_dSrmlJHrsT,
  intercom_tYaoKVt49Q,
  mixpanel_vYbGOFriae,
  navigation_QptjALbk5P,
  setCountry_56dWCNFicO,
  setUser_7ED3DT1SjL,
  userSnap_JoYSL8axbe,
  validationFields_ioW4FysPzn
]